/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { useIntl } from 'gatsby-plugin-intl';
import { PageHeader, Space, ProductItem } from '../Widgets';
import { Container, Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import * as Styles from './Styles/products.module.scss';

import ShrimpsImage from '../Resources/Images/Products/shrimps.jpeg';
import FishImage from '../Resources/Images/Products/fish.jpeg';
import CephaloPodsImage from '../Resources/Images/Products/cephaloPods.jpeg';
import CrayfishImage from '../Resources/Images/Products/crayfish.jpeg';
import ValueAdded from '../Resources/Images/Products/valueAdded.jpeg';

interface IProductsPage {}

const ProductsPage: React.FC<IProductsPage> = () => {
    const intl = useIntl();

    return (
        <Layout>
            <SEO
                title={intl.formatMessage({ id: 'products.pageTitle' })}
                description={intl.formatMessage({
                    id: 'products.metaDescription',
                })}
                lang={intl.locale}
            />

            <PageHeader
                title={intl.formatMessage({
                    id: 'products.bannerLabel',
                })}
            />

            <Container>
                <Row>
                    <Col
                        md={12}
                        className={classnames('pageContent', 'text-center')}>
                        <h1>{intl.formatMessage({ id: 'products.title' })}</h1>
                    </Col>
                </Row>

                <Space height={32} />

                <Row>
                    <Col
                        md={12}
                        lg={4}
                        className={classnames(Styles.productCell)}>
                        <ProductItem
                            imageSource={ShrimpsImage}
                            link={`/${intl.locale}/products/shrimp`}
                            label={intl.formatMessage({
                                id: 'products.shrimps',
                            })}
                            backContent={intl.formatMessage({
                                id: 'products.shrimps.description',
                            })}
                        />
                    </Col>
                    <Col
                        md={12}
                        lg={4}
                        className={classnames(Styles.productCell)}>
                        <ProductItem
                            imageSource={FishImage}
                            link={`/${intl.locale}/products/fish`}
                            label={intl.formatMessage({
                                id: 'products.fish',
                            })}
                            backContent={intl.formatMessage({
                                id: 'products.fish.description',
                            })}
                        />
                    </Col>
                    <Col
                        md={12}
                        lg={4}
                        className={classnames(Styles.productCell)}>
                        <ProductItem
                            imageSource={CephaloPodsImage}
                            link={`/${intl.locale}/products/cephalo-pods`}
                            label={intl.formatMessage({
                                id: 'products.cephaloPods',
                            })}
                            backContent={intl.formatMessage({
                                id: 'products.cephaloPods.description',
                            })}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col lg={2} className="d-none d-lg-block" />
                    <Col
                        md={12}
                        lg={4}
                        className={classnames(Styles.productCell)}>
                        <ProductItem
                            imageSource={CrayfishImage}
                            link={`/${intl.locale}/products/crayfish`}
                            label={intl.formatMessage({
                                id: 'products.crayfish',
                            })}
                            backContent={intl.formatMessage({
                                id: 'products.crayfish.description',
                            })}
                        />
                    </Col>
                    <Col
                        md={12}
                        lg={4}
                        className={classnames(Styles.productCell)}>
                        <ProductItem
                            imageSource={ValueAdded}
                            link={`/${intl.locale}/products/value-added`}
                            label={intl.formatMessage({
                                id: 'products.valueAdded',
                            })}
                            backContent={intl.formatMessage({
                                id: 'products.valueAdded.description',
                            })}
                        />
                    </Col>
                    <Col lg={2} className="d-none d-lg-block" />
                </Row>
            </Container>

            <Space height={40} />
        </Layout>
    );
};

export default ProductsPage;
